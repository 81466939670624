import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import arrow from './img/icons/arrow.svg';


const styles = (theme: Theme) => createStyles({
  '@keyframes vertical-move-arrow': {
        '0%, 100%': {top: 0, opacity: 0.5},
        '50%, 75%': {top: '30px', opacity: 1},
  },
  container: {
    '@media (max-width: 959px) and (orientation: landscape)': {
      display: 'none',
    },
    '@media screen and (max-width: 600px)': {
        display: 'none',
     },
    width: '100%',
    
    height: '100px',
    position: "absolute",
    zIndex: 100,
    display: 'flex',
    margin: '0 auto',
    bottom: 0,
    justifyContent: 'center',

  },

  arrow: {
    height: '53px',
    width: '30px',
    margin: '0 auto',
    animation: "vertical-move-arrow 3s ease infinite",
    position: 'relative',
    top: '0',
    textAlign: 'center',

    
  },
  
});



class Arrow extends React.Component<WithStyles<typeof styles>> {

public render() {
    const { classes } = this.props;
    return (
      
        <div className={classes.container} id="arrow">
         <a href="#main" className={classes.arrow}>
            <img src={arrow} />
         </a>
      </div>

      
      
 
        
    );
  }
}

export default withStyles(styles)(Arrow);