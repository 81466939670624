import { createMuiTheme,  MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import BackgroundDinner from './background-dinner';
import MainLinks from './main-links';
import Navbar from './navbar';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  }
});

class App extends React.Component {
  public render() {
    return (
      <div>
         <MuiThemeProvider theme={theme}>
            <Navbar />
            <BackgroundDinner />
            <MainLinks />
         </MuiThemeProvider>
      </div >
    );
  }
}

export default App;
