import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from './img/icons/icon.svg';

const styles = (theme: Theme) => createStyles({

  container: {
    '@media screen and (max-width: 599px)': {
      height: '100%',
      position: 'relative',
      bottom: '',
    },
    height: '50vh',
    position: 'absolute',
    width: '100%',
    margin: '0 auto',
    bottom: 0,
  },

  icon: {
    '@media (max-width: 959px) and (orientation: landscape)': {
      marginTop: '20vh',
    },

    '@media screen and (max-width: 599px)': {
      width: '42px',
      height: '42px',
      marginTop: '48px',
      marginBottom: '28px',
    },
    background: 'url(' + Icon + ')  center/cover no-repeat',
    height: '94px',
    width: '94px',
    position: 'relative',
    margin: '0 auto',
    marginTop: '12vh',
    marginBottom: '12vh',
  },


  bar: { 
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
   
  },

  item: {
    '@media screen and (max-width: 599px)': {
      fontSize: '12px',

    },
    '@media screen and (max-width: 360px)': {
      fontSize: '10px',

    },
    fontSize: '14px',
    color: '#A56E50',
    textTransform: 'uppercase',
    fontFamily: 'google_sansbold',
    letterSpacing: 0.05,
  },

  copyMark: {
    '@media screen and (max-width: 599px)': {
        fontSize: '5vw',
        padding: '0 1.2vw',
      },
    color: '#A56E50',
    fontSize: '24px',
    padding: '1.43vh 2.14vh',
  }


});

class Footer extends React.Component<WithStyles<typeof styles>> {
  
public render() {
    const { classes } = this.props;
    return (
        <div className={classes.container}>
          <a href="/">
            <div className={classes.icon}/>
          </a>
          <AppBar position="static" className={classes.bar}>
          <Toolbar>
            <Typography variant="body1" className={classes.item}>
            2019
            </Typography>
            <Typography variant="body1"  className={classes.copyMark}>
            &copy;
            </Typography>
            <Typography variant="body1" className={classes.item}>
            D. Daniels Enterprises. All right reserved
            </Typography>
          </Toolbar>
        </AppBar>     
        </div>
        
    );
  }
}

export default withStyles(styles)(Footer);