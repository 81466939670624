import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Footer from './footer';
import { Hidden } from '@material-ui/core';


const CARDS = [
  { description: "About the show", link: "http://store.dinnerwithdani.com/about", target: "_self" },
  { description: "Watch now on\n amazon - UK Only", link: "https://www.amazon.co.uk/Dinner-with-Dani-S1EP1/dp/B07HJWBLLV/", target: "_blank" },
  { description: "Watch now\n available globally", link: "http://store.dinnerwithdani.com/", target: "_self" },
  { description: "Watch now on\namazon - US Only", link: "https://www.amazon.com/Dinner-With-Dani/dp/B07HJTS77K", target: "_blank" },
  { description: "My Blog", link: "http://store.dinnerwithdani.com/blog", target: "_self" },
];


const styles = (theme: Theme) => createStyles ({
  container: {
    '@media screen and (max-width: 599px)': {
      marginTop: '8vw',
      paddingBottom: '32px',
    },
    marginTop: '10vh',
    minHeight: 'calc(100vh - 10vh)',
    backgroundColor: '#0f171e',
    color: '#fff',
    position: 'relative',
  },

  containerCards: {
    '@media screen and (max-width: 599px)': {
      paddingBottom: 0,
    },
    padding: 0,
    paddingBottom: '5.43vh',
   
  },

  cards: {
    '&:nth-child(odd)': {
       paddingTop: '1.43vw',
    },

    '&:nth-child(2)': {
      paddingTop: '3.79vw',
     
    },
    '&:nth-child(6)': {
      paddingTop: '3.79vw',
 
    },

    '@media screen and (max-width: 599px)': { 

      padding: '4vw',
        // About
        '&:nth-child(2)': {
          order: 7,
          paddingTop: 0,
        },

        // uk
        '&:nth-child(3)': {
          order: 5,
          paddingTop: 0,
        },

        // globally
        '&:nth-child(4)': {
          order: 2,
          padding: '8vw 28.5vw',
        },
       // us
      '&:nth-child(5)': {
        order: 4,
        paddingTop: 0,
      },

      // blog
      '&:nth-child(6)': {
        order: 6,
        paddingTop: 0,
      },
     

    },
    margin: '0 auto',
    padding: '0 1vw',
    textAlign: 'center',
  },


  image: {
    '@media screen and (max-width: 599px)': {
      paddingBottom: '3vw',
      paddingTop: '3vw',
  }, 
    margin: '0 auto',
    paddingBottom: '1.6vh',
    width: '100%',
  },

  link: {
    '@media screen and (max-width: 599px)': {
        fontSize: '3vw',
    }, 
    '&:hover': {
      opacity: 0.6,
    },
    color: '#fff',
    fontSize: '14px',
    fontFamily: "google_sansbold",
    textDecoration: 'none',
    textTransform: 'uppercase',
  },

  description: {
    lineHeight: '24px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },

   about: {
    '@media screen and (max-width: 599px)': {
      height: '42vw',
      width: '42vw',
      borderRadius: '7vw 5vw 7vw 5vw', 
    }, 
    borderRadius: '3.57vw 2.14vw 3.57vw 2.14vw', 
    height: '15.3vw',
    width: '15.3vw',
    marginBottom: '2vh',
    background: 'url(' + require("./img/3-globally.webp") + ') center/cover no-repeat, url(' + require("./img/3-globally.jpg") + ') center/cover no-repeat',
   },

   whatchUk: {
    '@media screen and (max-width: 599px)': {
      height: '42vw',
      width: '42vw',
      borderRadius: '7vw 5vw 7vw 5vw', 
    },  
    borderRadius: '2.14vw 3.57vw 2.14vw 3.57vw ', 
    height: '15.3vw',
    width: '15.3vw',
    marginBottom: '2vh',
    background: 'url(' + require("./img/5-Amazonuk.webp") + ') center/cover no-repeat, url(' + require("./img/5-Amazonuk.jpg") + ') center/cover no-repeat',
   },

   globaly: {
    '@media screen and (max-width: 599px)': {
      height: '42vw',
      width: '42vw',
      borderRadius: '5vw', 
    },  
    borderRadius: '3.57vw 2.14vw 3.57vw 2.14vw', 
    height: '15.3vw',
    width: '15.3vw',
    marginBottom: '2vh',
    background: 'url(' + require("./img/1-Amazonus.webp") + ') center/cover no-repeat, url(' + require("./img/1-Amazonus.jpg") + ') center/cover no-repeat',
   },

   whatchUs: {
    '@media screen and (max-width: 599px)': {
      height: '42vw',
      width: '42vw',
      borderRadius: '5vw 7vw 5vw 7vw', 
    }, 
    borderRadius: '2.14vw 3.57vw 2.14vw 3.57vw ', 
    height: '15.3vw',
    width: '15.3vw',
    marginBottom: '2vh',
    background: 'url(' + require("./img/2-About.webp") + ') center/cover no-repeat, url(' + require("./img/2-About.jpg") + ') center/cover no-repeat',
   },

   blog: {
    '@media screen and (max-width: 599px)': {
      height: '42vw',
      width: '42vw',
      borderRadius: '5vw 7vw 5vw 7vw', 
    }, 
    borderRadius: '3.57vw 2.15vw 3.57vw 2.14vw', 
    height: '15.3vw',
    width: '15.3vw',
    marginBottom: '2vh',
    background: 'url(' + require("./img/4-Blog.webp") + ') center/cover no-repeat, url(' + require("./img/4-Blog.jpg") + ') center/cover no-repeat',
   },
  
});

class MainLinks extends React.Component<WithStyles<typeof styles>> {
  public render() {
    const { classes } = this.props;
    return (  
      <div className={classes.container} id="main">
        <Grid container={true} className={classes.containerCards}>
        <Grid item={true} sm={1} />
          <Grid item={true} xs={6} sm={2} className={classes.cards}>
            <a href={CARDS[0].link} target={CARDS[0].target} className={classes.link}>
              <div className={classes.about} />
                
              <span className={classes.description}>{CARDS[0].description}</span>
            </a>
          </Grid>
          <Grid item={true} xs={6} sm={2} className={classes.cards}>
            <a href={CARDS[1].link} target={CARDS[1].target} className={classes.link}>
            <div className={classes.whatchUk} />
              <span className={classes.description}>{CARDS[1].description}</span>
            </a>
          </Grid>
          <Grid item={true} xs={12} sm={2} className={classes.cards}>
            <a href={CARDS[2].link} target={CARDS[2].target} className={classes.link}>
            <div className={classes.globaly} />
              <span className={classes.description}>{CARDS[2].description}</span>
            </a>
          </Grid>
          <Grid item={true} xs={6} sm={2} className={classes.cards}>
            <a href={CARDS[3].link} target={CARDS[3].target} className={classes.link}>
            <div className={classes.whatchUs} />
              <span className={classes.description}>{CARDS[3].description}</span>
            </a>
          </Grid>
          <Grid item={true} xs={6} sm={2} className={classes.cards}>
            <a href={CARDS[4].link} target={CARDS[4].target} className={classes.link}>
            <div className={classes.blog} />
              <span className={classes.description}>{CARDS[4].description}</span>
            </a>
          </Grid>

        <Grid item={true} sm={1}/>
      </Grid>
      <Footer />
    </div>
      
    );
  }
}

export default withStyles(styles)(MainLinks);