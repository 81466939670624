import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Icon from './img/icons/icon.svg';
import Grid from '@material-ui/core/Grid';


const styles = (theme: Theme) => createStyles({
  bar: {
    '@media screen and (max-width: 599px)': {
      backgroundColor: '#000',
    },
    backgroundColor: '#0F171D',
    alignItems: 'center',
    justifyContent: 'center',
  },

  icon: {
    '@media screen and (max-width: 599px)': {
      width: '30px',
      height: '30px',
      padding: '14px 16px',
    },
    width: '42px',
    height: '42px',
    padding: '18px 52px 14px 52px',
    lineHeight: '74px',
  },

  link: {
    '@media screen and (max-width: 599px)': {
      fontSize: '12px',
      padding: '14px 8px',
    },
    '&:hover': {
      opacity: 0.6,
    },
    fontSize: '16px',
    color: '#fff',
    cursor: 'pointer',
    padding: '14px 28px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: '"Google_sansregular"',
  },
  iconLink: {
    // padding: '14px 28px',
  }
});

function NavBar(props: any) {
  const { classes } = props;
  return (
     <Grid container={true} justify="center" alignItems="center" >
      <AppBar position="static" className={classes.bar}>
        <Toolbar>
          <Typography variant="body1" color="default">
            <a href="http://dinnerwithdani.com" className={classes.link}>Home</a>
          </Typography>
          <Typography variant="body1" color="default">
            <a href="http://store.dinnerwithdani.com/about" className={classes.link}>About</a>
          </Typography>
              <a href="/" className={classes.iconLink}>
                <img src={Icon} className={classes.icon} />
              </a>
          <Typography variant="body1" color="default">
            <a href="http://store.dinnerwithdani.com/" className={classes.link}>Watch</a>
          </Typography>
          <Typography variant="body1" color="default">
            <a href="http://store.dinnerwithdani.com/blog" className={classes.link}>Blog</a>
          </Typography>
        </Toolbar>
      </AppBar>
    </Grid>
 
  );
}


export default withStyles(styles)(NavBar);