import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import BackgroundImgWebp from './img/background.webp';
import BackgroundImgJpg from './img/background.jpg';
import BackgroundForks from './img/background-forks.png';
import Logo from './img/icons/logo.svg';
import Arrow from './arrow';

const styles = (theme: Theme) => createStyles({

  container: {
    '@media screen and (max-width: 599px)': {
      height: '179px',
    },
    padding: 0,
    height: 'calc(100vh - 80px)',
    position: 'relative',
    
  },

  backImg: {
    '@media (max-width: 959px) and (orientation: landscape)': {
      height: 'calc(100vh - 80px)',
      backgroundPositionY: '-20px',
    },

    '@media screen and (max-width: 599px)': {
      height: '179px',
      backgroundPositionY: '-50px',
    },
    '@media screen and (max-width: 360px)': {
      height: '179px',
      backgroundPositionY: '-50px',
    },
    '@media screen and (min-width: 1401px)': {
      backgroundPositionY: '50%',
      
    },
    height: '698px',
    background: 'url(' + BackgroundImgWebp + ') center/cover no-repeat, url(' + BackgroundImgJpg + ') center/cover no-repeat',
    backgroundPositionY: '85%',
    padding: 0,
    position: 'relative',
    width: '100%',
  },

  overlay: {
    '@media screen and (max-width: 599px)': {
      
    },
    '@media screen and (min-width: 1401px)': {
      height: '20vw',
    },
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(15,23,30,1) 90%)' ,
    bottom: '-2px',
    height: '25vw',
    left: '0px',
    position: 'absolute',
    right: '0px',
    width: '100%',
  },
  
  overlayForks: {
  
    '@media screen and (min-width: 1401px)': {
      width: '96%',
    },
    background: 'url(' + BackgroundForks + ') center/cover no-repeat' ,
    bottom: '5vh',
    height: '15vw',
    left: '0px',
    position: 'absolute',
    right: '0px',
    width: '90%',
    margin: '0 auto',
 
  },

  logo: {
    '@media (max-width: 959px) and (orientation: landscape)': {
      width: '118px',
      height: '120px',
      bottom: 0,
    },

    '@media screen and (max-width: 599px)': {
      width: '118px',
      height: '120px',
      bottom: '-30px',
    },
    '@media screen and (min-width: 1401px)': {
      bottom: '85px',
    },
    background: 'url(' + Logo + ')  center/cover no-repeat',
    bottom: '100px',
    height: '302px',
    width: '305px',
    left: '0px',
    position: 'absolute',
    margin: '0 auto',
    right: '0px',
  },

});

class Back extends React.Component<WithStyles<typeof styles>> {
  
public render() {
    const { classes } = this.props;
    return (
      <Grid container={true} className={classes.container} >
        <Grid item={true} sm={12} className={classes.backImg}>
          <div className={classes.overlay} />
          <div className={classes.overlayForks} />
        </Grid>
        <Grid item={true} sm={12}>
          <div className={classes.logo} />
          <Arrow />
        </Grid>

      </Grid>
    );
  }
}

export default withStyles(styles)(Back);